body {
  font-size: 14px;
  font-family: "Montserrat", sans-serif !important;
  margin: 0;
  padding: 0;
}

.App {
  min-height: 100vh;
  background-color: #f7f8f9;
  font-family: "Montserrat";
  font-style: normal;
}

a {
  text-decoration: none;
}

a:-webkit-any-link {
  color: #000000;
}

span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  border-left: 1px solid #e7e7ed;
  height: 100%;
  line-height: 2.2;
  padding: 0 14px;
  color: #272833 !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #f9b233 !important;
}

.MuiRadio-root {
  color: #cdced9 !important;
}

.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator,
.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-clearIndicator.MuiAutocomplete-clearIndicatorDirty {
  display: none;
}

.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-inputRoot {
  padding-right: 0 !important;
}

/* button {
  width: 23vw;
  font-size: 1.1rem;
  font-weight: 600;
  color: #6b6c7e;
  cursor: pointer;
} */

/* button:focus {
  background-color: transparent;
  color: #f5f5f5;
} */

.space-above-item {
  margin-top: 55px !important;
}

.space-above-item-plus {
  margin-top: 100px !important;
}

.space-above-item-less {
  margin-top: 16px !important;
}

input {
  margin: 3px 0 0 0 !important;
  border: 1px solid #e7e7ed;
  text-indent: 0.4rem;
  border-radius: 4px !important;
  box-sizing: border-box !important;
}

input.currency {
  text-indent: 20%;
}

.file-path-wrapper {
  border: 1px solid #e7e7ed !important;
  border-radius: 4px;
}

.file-path-wrapper > input {
  border: none !important;
}

.forgot-password-text {
  font-size: 10px;
}

input span {
  margin-top: 0 !important;
}

textarea {
  border: 1px solid #e7e7ed !important;
  border-radius: 4px;
  width: 100%;
  margin-top: 3px;
  resize: vertical !important;
  font-family: "Montserrat";
  font-size: 16px;
  text-indent: 0.4rem;
  padding: 12px 0 12px 0;
}

textarea:hover {
  border: 1px solid #e7e7ed;
  background-color: "tomato";
}

.btn-side {
  margin-left: 20px;
}

.input-side {
  margin-left: 10px !important;
}

.input-side-plus {
  margin-left: 20px !important;
}

.error-input {
  color: #da1414;
  font-size: 0.9rem;
}

.radio-button {
  display: flex;
  height: 40px;
  max-width: 360px;
  border: 1px solid #e7e7ed;
  border-radius: 4px;
  align-items: center;
  margin-bottom: 10px !important;
  cursor: pointer;
}

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:before,
[type="radio"].with-gap:checked + label:after {
  border: 4px solid #f9b233;
}

[type="radio"]:checked + label:after,
[type="radio"].with-gap:checked + label:after {
  display: none;
}

.radio-button label {
  margin-left: 10px;
}

.radio-button div.border {
  display: flex;
  height: 40px;
  border-left: 1px solid #e7e7ed;
  align-items: center;
}

.radio-button div.border span {
  text-indent: 10px;
}

[type="radio"]:not(:checked) + label:before,
[type="radio"]:not(:checked) + label:after {
  border: 1px solid #cdced9;
}

input.disabled {
  cursor: not-allowed !important;
}

.label-input {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #272833;
}

.subtitle.text-grey {
  font-weight: 600;
  color: #6b6c7e;
}

.grey-text {
  color: #393a4a !important;
}

.light-grey-text {
  color: #a1a1a1 !important;
}

.subtitle.text-black {
  font-weight: 600;
  color: #393a4a;
}

.screen {
  display: grid;
  height: 100vh;
  grid-template-rows: 1fr 80px;
  grid-template-columns: 1fr 4fr;
  grid-template-areas:
    "sidebar-image content"
    "sidebar-image bottom-bar";
}

.select-wrapper {
  display: flex;
  align-items: center;
}

.select-wrapper svg {
  position: absolute;
  right: 3%;
}

.sidebar-image {
  grid-area: sidebar-image;
}

.sidebar-image {
  height: 100%;
  width: 100%;
  background-image: url("./assets/images/login/image-back.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}

.sidebar-image img {
  height: 11%;
  width: 11%;
  position: absolute;
  left: 4.5%;
  right: 82.89%;
  top: 5.42%;
  bottom: 87.22%;
}

.content {
  grid-area: content;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-left: 7.8vw;
  margin-bottom: 20px;
}

.bottom-bar {
  grid-area: bottom-bar;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 0 7.8vw;
}

.background-grey {
  min-height: 100%;
  background-color: #f7f8f9;
}

.background-grey > div {
  margin: 0 30px;
}

.page-title h1 {
  font-size: 1.71rem;
  font-weight: 600;
  margin: 30px 0;
}

@media (max-width: 993px) {
  .screen {
    display: grid;
    height: 100vh;
    grid-template-rows: 1fr 80px;
    grid-template-columns: 320px 1fr;
    grid-template-areas:
      "content content"
      "bottom-bar bottom-bar";
  }
}
